body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color:  rgb(100, 25, 32);
}

.scroll::-webkit-scrollbar {
    width: 5px;
}

.scroll::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: transparent;
}

.scroll::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #c9d2d4;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.349);
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.349);
    padding-inline: 2px;
}
.scroll::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(233, 228, 228, 0.8);
}